import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "min-width: 100px"
      },
      form: _vm.createForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "类目"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["parentcategoryid", {
        rules: [{
          required: true,
          message: "请选择一级类目"
        }]
      }],
      expression: "[\n                    'parentcategoryid',\n                    {\n                        rules: [\n                            { required: true, message: '请选择一级类目' },\n                        ],\n                    },\n                ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      disabled: _vm.showdisable
    },
    on: {
      change: _vm.handleProvinceChange
    }
  }, _vm._l(_vm.seriesCateList, function (it) {
    return _c("a-select-option", {
      key: it.id
    }, [_vm._v(" " + _vm._s(it.name) + " ")]);
  }), 1), _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["categoryid", {
        rules: [{
          required: true,
          message: "请选择二级类目"
        }]
      }],
      expression: "[\n                    'categoryid',\n                    {\n                        rules: [\n                            { required: true, message: '请选择二级类目' },\n                        ],\n                    },\n                ]"
    }],
    staticStyle: {
      width: "262px",
      "margin-left": "20px"
    },
    attrs: {
      disabled: _vm.showdisable
    }
  }, _vm._l(_vm.secategoryList, function (it) {
    return _c("a-select-option", {
      key: it.id
    }, [_vm._v(" " + _vm._s(it.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "品牌"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["brandid", {
        rules: [{
          required: true,
          message: "请选择品牌"
        }]
      }],
      expression: "[\n                    'brandid',\n                    {\n                        rules: [{ required: true, message: '请选择品牌' }],\n                    },\n                ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      disabled: _vm.showdisable,
      "show-search": "",
      "filter-option": _vm.filterOption
    }
  }, _vm._l(_vm.brandList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "尺码类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sizetype", {
        rules: [{
          required: true,
          message: "请选择尺码类型"
        }]
      }],
      expression: "[\n                    'sizetype',\n                    {\n                        rules: [\n                            { required: true, message: '请选择尺码类型' },\n                        ],\n                    },\n                ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      disabled: _vm.showdisable
    },
    on: {
      change: _vm.sizetypeChange
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 球鞋 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 服饰 ")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "选择款式"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        rules: [{
          required: true,
          message: "请选择款式"
        }]
      }],
      expression: "[\n                    'type',\n                    {\n                        rules: [{ required: true, message: '请选择款式' }],\n                    },\n                ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      disabled: _vm.showdisable,
      mode: "multiple",
      "filter-option": _vm.filterOption
    },
    on: {
      change: _vm.handleChange
    }
  }, _vm._l(_vm.sexSizeType, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _vm.createForm.getFieldValue("sizetype") == 1 ? _c("div", _vm._l(_vm.itemtype, function (item, index) {
    return _c("div", {
      key: item.id
    }, [_c("a-form-item", {
      attrs: {
        label: "选择尺码"
      }
    }, [_c("div", [_vm._v(_vm._s(_vm.sexSizeType[item.type].name))]), _c("a-select", {
      staticStyle: {
        width: "536px",
        "margin-bottom": "16px"
      },
      attrs: {
        disabled: _vm.showdisable,
        placeholder: "请选择一个尺码作为APP购买时展示的尺码"
      },
      model: {
        value: _vm.itemtype[index].showtype,
        callback: function callback($$v) {
          _vm.$set(_vm.itemtype[index], "showtype", $$v);
        },
        expression: "itemtype[index].showtype"
      }
    }, _vm._l(_vm.showtype, function (item) {
      return _c("a-select-option", {
        key: item.id
      }, [_vm._v(" " + _vm._s(item.name) + " ")]);
    }), 1), _c("a-table", {
      staticClass: "tableone",
      attrs: {
        pagination: false,
        dataSource: _vm.itemtype[index].items,
        columns: _vm.columns,
        scroll: {
          x: 536
        }
      },
      scopedSlots: _vm._u([{
        key: "size",
        fn: function fn(text, _ref) {
          var rowindex = _ref.rowindex,
            size = _ref.size;
          return [_c("a-input", {
            staticStyle: {
              width: "100%",
              "text-align": "center"
            },
            attrs: {
              disabled: _vm.showdisable,
              value: size
            },
            on: {
              change: function change(value) {
                return _vm.changeHandle(value, rowindex, "size", index);
              }
            }
          })];
        }
      }, {
        key: "eu",
        fn: function fn(text, _ref2) {
          var rowindex = _ref2.rowindex,
            eu = _ref2.eu;
          return [_c("a-input", {
            staticStyle: {
              width: "100%",
              "text-align": "center"
            },
            attrs: {
              disabled: _vm.showdisable,
              value: eu
            },
            on: {
              change: function change(value) {
                return _vm.changeHandle(value, rowindex, "eu", index);
              }
            }
          })];
        }
      }, {
        key: "uk",
        fn: function fn(text, _ref3) {
          var rowindex = _ref3.rowindex,
            uk = _ref3.uk;
          return [_c("a-input", {
            staticStyle: {
              width: "100%",
              "text-align": "center"
            },
            attrs: {
              disabled: _vm.showdisable,
              value: uk
            },
            on: {
              change: function change(value) {
                return _vm.changeHandle(value, rowindex, "uk", index);
              }
            }
          })];
        }
      }, {
        key: "cm",
        fn: function fn(text, _ref4) {
          var rowindex = _ref4.rowindex,
            cm = _ref4.cm;
          return [_c("a-input", {
            staticStyle: {
              width: "100%",
              "text-align": "center"
            },
            attrs: {
              disabled: _vm.showdisable,
              value: cm
            },
            on: {
              change: function change(value) {
                return _vm.changeHandle(value, rowindex, "cm", index);
              }
            }
          })];
        }
      }, {
        key: "jp",
        fn: function fn(text, _ref5) {
          var rowindex = _ref5.rowindex,
            jp = _ref5.jp;
          return [_c("a-input", {
            staticStyle: {
              width: "100%",
              "text-align": "center"
            },
            attrs: {
              disabled: _vm.showdisable,
              value: jp
            },
            on: {
              change: function change(value) {
                return _vm.changeHandle(value, rowindex, "jp", index);
              }
            }
          })];
        }
      }, {
        key: "more",
        fn: function fn(text, _ref6) {
          var rowindex = _ref6.rowindex;
          return [rowindex !== 0 && !_vm.showdisable ? _c("span", {
            staticStyle: {
              "margin-left": "10px"
            },
            on: {
              click: function click($event) {
                return _vm.deleteClick(rowindex, index);
              }
            }
          }, [_vm._v("删除")]) : _vm._e()];
        }
      }], null, true)
    }), _c("div", [!_vm.showdisable ? _c("a-button", {
      staticStyle: {
        width: "536px",
        color: "#3270f5"
      },
      on: {
        click: function click($event) {
          return _vm.addRow(index);
        }
      }
    }, [_vm._v("+ 添加一行")]) : _vm._e()], 1)], 1), _c("a-form-item", {
      attrs: {
        label: "备注"
      }
    }, [_c("a-textarea", {
      staticStyle: {
        width: "536px",
        "max-width": "536px"
      },
      attrs: {
        disabled: _vm.showdisable,
        placeholder: "该数据为手工测量，可能存在1-3cm的误差。",
        rows: 8
      },
      model: {
        value: _vm.itemtype[index].memo,
        callback: function callback($$v) {
          _vm.$set(_vm.itemtype[index], "memo", $$v);
        },
        expression: "itemtype[index].memo"
      }
    })], 1)], 1);
  }), 0) : _vm._e(), _vm.createForm.getFieldValue("sizetype") == 2 ? _c("div", _vm._l(_vm.itemtype, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("a-form-item", {
      attrs: {
        label: _vm.sexSizeType[item.type].name
      }
    }, [_c("a-input", {
      staticStyle: {
        width: "134px",
        "text-align": "center",
        color: "black",
        "font-weight": "700"
      },
      attrs: {
        value: "SIZE",
        disabled: ""
      }
    }), _vm._l(_vm.itemtype[index].items, function (item, i) {
      return _c("span", {
        key: i
      }, [_c("a-input", {
        staticStyle: {
          width: "134px"
        },
        attrs: {
          disabled: _vm.showdisable
        },
        model: {
          value: _vm.itemtype[index].items[i].size,
          callback: function callback($$v) {
            _vm.$set(_vm.itemtype[index].items[i], "size", typeof $$v === "string" ? $$v.trim() : $$v);
          },
          expression: "itemtype[index].items[i].size"
        }
      })], 1);
    }), _vm.itemtype[index].items.length > 1 ? _c("a-button", {
      staticStyle: {
        "min-width": "37px",
        "margin-left": "20px"
      },
      attrs: {
        disabled: _vm.showdisable
      },
      on: {
        click: function click($event) {
          return _vm.minusinput(index);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "minus"
      }
    })], 1) : _vm._e(), _c("a-button", {
      staticStyle: {
        "min-width": "37px"
      },
      attrs: {
        disabled: _vm.showdisable
      },
      on: {
        click: function click($event) {
          return _vm.plusinput(index);
        }
      }
    }, [_c("a-icon", {
      staticStyle: {
        color: "#3270f5"
      },
      attrs: {
        type: "plus"
      }
    })], 1)], 2), _c("a-form-item", {
      attrs: {
        label: "备注"
      }
    }, [_c("a-textarea", {
      staticStyle: {
        "min-width": "536px"
      },
      attrs: {
        disabled: _vm.showdisable,
        placeholder: "Basic usage",
        rows: 4
      },
      model: {
        value: _vm.itemtype[index].memo,
        callback: function callback($$v) {
          _vm.$set(_vm.itemtype[index], "memo", $$v);
        },
        expression: "itemtype[index].memo"
      }
    })], 1)], 1);
  }), 0) : _vm._e()], 1), !_vm.showdisable ? _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    staticClass: "btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd(1);
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["size:add:submit"],
      expression: "['size:add:submit']"
    }],
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1) : _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("返回")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };