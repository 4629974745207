import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { mapState, mapActions } from "vuex";
import Mixin from "@/utils/mixin";
import { SizeApi } from "@/api";
export default {
  name: "addSize",
  components: {},
  data: function data() {
    return {
      showtype: [{
        id: 1,
        name: "US",
        uname: "size"
      }, {
        id: 2,
        name: "EU",
        uname: "eu"
      }, {
        id: 3,
        name: "UK",
        uname: "uk"
      }, {
        id: 4,
        name: "CM",
        uname: "cm"
      }, {
        id: 5,
        name: "JP",
        uname: "jp"
      }],
      rules: {},
      createForm: this.$form.createForm(this, {
        name: "addsize"
      }),
      selectedRowKeys: [],
      columns: [{
        title: "US(美国)",
        dataIndex: "US",
        scopedSlots: {
          customRender: "size"
        },
        align: "center",
        width: "20%"
      }, {
        title: "EU(欧洲)",
        dataIndex: "EU",
        scopedSlots: {
          customRender: "eu"
        },
        align: "center",
        width: "20%"
      }, {
        title: "UK(英国)",
        dataIndex: "UK",
        scopedSlots: {
          customRender: "uk"
        },
        align: "center",
        width: "20%"
      }, {
        title: "CM(中国)",
        dataIndex: "CM",
        scopedSlots: {
          customRender: "cm"
        },
        align: "center",
        width: "20%"
      }, {
        title: "JP(日本)",
        dataIndex: "JP",
        scopedSlots: {
          customRender: "jp"
        },
        align: "center",
        width: "20%"
      }, {
        title: "",
        dataIndex: "more",
        scopedSlots: {
          customRender: "more"
        },
        width: 50
      }],
      itemtype: [],
      secategoryList: [],
      parentcategoryid: "",
      showtypeVisible: false
    };
  },
  mixins: [Mixin],
  computed: _objectSpread(_objectSpread({}, mapState("base", ["brandList", "seriesCateList"])), {}, {
    editId: function editId() {
      return this.$route.query.id;
    },
    showdisable: function showdisable() {
      return +this.$route.query.key === 1;
    }
  }),
  created: function created() {
    var _this = this;
    if (this.editId) {
      this.getDetail();
    }
    if (!this.showdisable) {
      this.getSeriesCateList();
      this.getBrandList();
    }
    this.parentcategoryid = this.$route.query.parentcategoryid;
    if (this.parentcategoryid) {
      this.seriesCateList.map(function (v, i) {
        if (_this.parentcategoryid == v.id) {
          _this.secategoryList = v.items;
        }
      });
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions("base", ["getBrandList", "getSeriesCateList"])), {}, {
    minusinput: function minusinput(index) {
      this.itemtype[index].items.splice(this.itemtype[index].items.length - 1, 1);
    },
    plusinput: function plusinput(index) {
      this.itemtype[index].items.push({
        rowindex: this.itemtype[index].items.length,
        size: ""
      });
    },
    handleAdd: function handleAdd(val) {
      var _this2 = this;
      if (val || this.showdisable) {
        this.$router.push("/size/index");
        return;
      }
      var categoryid = this.createForm.getFieldValue("categoryid");
      if (!categoryid) {
        this.toast("请选择二级分类", "error");
        return;
      }
      this.showtypeVisible = false;
      // let Arrnum = 0;
      if (this.createForm.getFieldValue("sizetype") == 1) {
        this.itemtype.map(function (v) {
          if (!v.showtype) {
            _this2.toast("请选择一个尺码作为APP购买时展示的尺码", "error");
            _this2.showtypeVisible = true;
            return;
          }
          v.cmArr = [];
          v.euArr = [];
          v.jpArr = [];
          v.vsArr = [];
          v.ukArr = [];
          v.items.map(function (vs, i) {
            // console.log(vs[this.showtype[v.showtype - 1].uname],'vs[this.showtype[v.showtype - 1].uname]',vs);
            if (!vs[_this2.showtype[v.showtype - 1].uname]) {
              _this2.toast("请填写展示在App内的尺码", "error");
              _this2.showtypeVisible = true;
            }
            if (vs.cm == "" && vs.eu == "" && vs.jp == "" && vs.size == "" && vs.uk == "") {
              v.items.splice(i);
            }
            v.cmArr.push(vs.size);
            v.euArr.push(vs.eu);
            v.jpArr.push(vs.jp);
            v.vsArr.push(vs.cm);
            v.ukArr.push(vs.uk);
          });
          if (v.items.length <= 0) {
            _this2.toast("请添加尺码", "error");
            _this2.showtypeVisible = true;
            return;
          }
          if (_this2.arrCheck(v.cmArr) || _this2.arrCheck(v.euArr) || _this2.arrCheck(v.jpArr) || _this2.arrCheck(v.vsArr) || _this2.arrCheck(v.ukArr)) {
            return;
          }
          if (v.items.length <= 0) return;
          if (_this2.showtypeVisible) return;
        });
      } else {
        this.itemtype.map(function (v) {
          console.log(v.showtype, v, 'vvvvvvvvvvvvvvvvvvvvvvvv');
          if (!v.showtype) {
            _this2.toast("请选择一个尺码作为APP购买时展示的尺码", "error");
            return;
          }
          v.items.map(function (vs, i) {
            if (vs[_this2.showtype[v.showtype - 1].uname] == "") {
              _this2.toast("请填写展示在App内的尺码", "error");
              _this2.showtypeVisible = true;
            }
          });
          if (v.items.length <= 0) return;
        });
      }

      // console.log(this.showtypeVisible);
      if (this.showtypeVisible) return;
      this.itemtype = this.itemtype.map(function (v) {
        var type = v.type,
          memo = v.memo,
          showtype = v.showtype,
          items = v.items;
        return {
          type: type,
          memo: memo,
          showtype: showtype,
          items: items
        };
      });
      this.createForm.validateFields(function (err, value) {
        var brandid = value.brandid,
          categoryid = value.categoryid,
          sizetype = value.sizetype,
          parentcategoryid = value.parentcategoryid;
        if (!err) {
          var params = {
            brandid: brandid,
            categoryid: categoryid,
            sizetype: sizetype,
            parentcategoryid: parentcategoryid,
            types: _this2.itemtype
          };
          console.log(params);
          if (!_this2.editId) {
            SizeApi.saveSize(params).then(function (res) {
              if (res) {
                _this2.$router.push("/size/index?addType=1");
              }
            });
          } else {
            params = _objectSpread(_objectSpread({}, params), {}, {
              id: _this2.editId
            });
            SizeApi.updateSize(params).then(function (res) {
              if (res) {
                _this2.$router.push("/size/index");
              }
            });
          }
        } else {
          console.log(err);
        }
      });
    },
    handleChange: function handleChange(e) {
      console.log(2323, e, this.itemtype);
      if (e.length > this.itemtype.length || e.length == 0 && this.itemtype.length == 0) {
        if (this.createForm.getFieldValue("sizetype") == 2) {
          this.itemtype.push({
            type: e[e.length - 1],
            memo: "",
            showtype: 1,
            items: [{
              size: ""
            }]
          });
        } else {
          this.itemtype.push({
            type: e[e.length - 1],
            memo: "",
            showtype: undefined,
            items: [{
              rowindex: 0,
              size: "",
              eu: "",
              uk: "",
              cm: "",
              jp: ""
            }]
          });
        }
      }
      this.itemtype = this.itemtype.filter(function (item) {
        return e.includes(item.type);
      });
    },
    sizetypeChange: function sizetypeChange() {
      this.itemtype = [];
      this.createForm.setFieldsValue({
        type: []
      });
    },
    // 添加尺码每一行
    addRow: function addRow(index) {
      this.itemtype[index].items.push({
        rowindex: this.itemtype[index].items.length,
        size: "",
        eu: "",
        uk: "",
        cm: "",
        jp: ""
      });
    },
    // 删除一行
    deleteClick: function deleteClick(rowindex, index) {
      this.itemtype[index].items = this.itemtype[index].items.filter(function (v) {
        return v.rowindex != rowindex;
      });
    },
    changeHandle: function changeHandle(e, i, column, index) {
      // console.log(e, i, column);
      var newData = _toConsumableArray(this.itemtype[index].items);
      var target = newData && newData.find(function (item) {
        return item.rowindex == i;
      });
      if (target) {
        target[column] = e.target.value;
        this.itemtype[index].items = newData;
      }
      // console.log(this.itemtype);
    },
    // 获取详情
    getDetail: function getDetail() {
      var _this3 = this;
      SizeApi.findoneSize({
        id: this.editId
      }).then(function (res) {
        // console.log(res);
        _this3.createForm.setFieldsValue({
          categoryid: res.categoryid,
          parentcategoryid: res.parentcategoryid,
          brandid: res.brandid,
          type: res.type,
          sizetype: res.sizetype
        });
        _this3.itemtype.push({
          type: res.type,
          memo: res.memo,
          showtype: res.showtype,
          items: res.items
        });
        _this3.itemtype[0].items.map(function (v, i) {
          v.rowindex = i;
        });
      });
    },
    handleProvinceChange: function handleProvinceChange(id) {
      var _this4 = this;
      this.seriesCateList.map(function (v, i) {
        if (v.id == id) {
          _this4.secategoryList = v.items;
        }
      });
      this.createForm.setFieldsValue({
        parentcategoryid: "",
        categoryid: ""
      });
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    // 循环添加数组
    arrCheck: function arrCheck() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      console.log(arr, "arr");
      var Arrnum = 0;
      arr.forEach(function (v) {
        if (v) Arrnum++;
      });
      if (Arrnum > 0 && Arrnum < arr.length) {
        this.toast("请先完成当前的编辑", "error");
        this.showtypeVisible = true;
      } else {
        return;
      }
      console.log(this.showtypeVisible);
    }
  })
};